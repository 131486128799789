<template>
  <div>
    <!-- Resultado de cada momento contable respectivamente -->
    <b-card no-body class="my-3">
      <b-tabs card>
        <b-tab v-for="res in importPoliza.result" :key="res.id" :title="res.content.poliza.momento">
          <polizas-finanzas-card :item="res"></polizas-finanzas-card>
        </b-tab>
      </b-tabs>
    </b-card>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import PolizasFinanzasCard from '@/components/Polizas/Finanzas/PolizasFinanzasCard'

export default {
  name: 'PolizasFinanzasTabs',

  components: {
    PolizasFinanzasCard
  },

  computed: {
    ...mapState('polizasFModule', ['importPoliza'])
  }
}
</script>

<style lang="scss" scoped></style>
